import logo_full from "@public/logos/logo-full-color.png";
import logo_full_white from "@public/logos/logo-full-white.png";
import logo_square from "@public/logos/logo-square-color.png";
import logo_square_white from "@public/logos/logo-square-white.png";
import { Img } from "react-image";

export default function ApplicationLogo({
    white = false,
    square = false,
    ...rest
}) {
    return (
        <Img {...rest} alt="..." src={
            !square
                ? white
                    ? logo_full_white
                    : logo_full
                : white
                    ? logo_square_white
                    : logo_square
        }
        />
    );
}
